import React, { useRef, useState, useEffect } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from 'react-portable-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	Dialog,
	Grid,
	makeStyles,
	Typography,
	useMediaQuery,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		position: 'relative',
		// cursor: 'pointer',
		// '&:hover': {
		// 	'& $headshotBackground': {
		// 		opacity: 0.7,
		// 	},
		// },
	},
	headshotBackground: {
		position: 'absolute',
		padding: 'inherit',
		width: '100%',
		zIndex: 0,
		aspectRatio: '1/1',
		borderRadius: '50%',
		opacity: 0.1,
		// height: '250px',
		transition: 'all .25s ease-in-out',
		bottom: 0,
	},
	headshot: {
		position: 'relative',
		zIndex: 1,
		opacity: 1,
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
	dialogWrapper: {
		[theme.breakpoints.up('lg')]: {
			paddingLeft: '2rem',
		},
	},
}));

export const LeadershipDialog = ({
	open,
	handleClose,
	handleNext,
	handlePrev,
}) => {
	const classes = useStyles();
	const textRef = useRef(null);
	const [openBody, setOpenBody] = useState(false);

	useEffect(() => {
		setOpenBody(false);
	}, [open]);

	useEffect(() => {
		if (textRef?.current?.innerText?.length > 200) {
			setOpenBody(true);
		}
	}, [textRef]);

	const xs = useMediaQuery('(max-width: 481px)');
	const lg = useMediaQuery('(max-width: 1280px)');

	return (
		open &&
		open.length && (
			<Dialog
				maxWidth='lg'
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'>
				<Grid container direction='row' justifyContent='flex-end'>
					<FontAwesomeIcon
						onClick={handleClose}
						icon={['fas', 'xmark']}
						style={{
							color: '#002D5C',
							height: '40px',
							width: '40px',
							cursor: 'pointer',
							margin: '8px 14px ',
						}}
					/>
				</Grid>
				<Grid
					container
					direction='row'
					justifyContent={lg && 'center'}
					style={{ padding: '1rem' }}>
					<Grid
						item
						sm={3}
						xs={12}
						spacing={4}
						container
						direction='column'
						justifyContent='center'
						alignItems='center'>
						<div className={classes.wrapper}>
							<div
								className={classes.headshotBackground}
								style={{
									background: open[0]?.cardAccentColor[0].hexValue ?? '#002d5c',
								}}
							/>
							<GatsbyImage
								image={open[0].headshot.asset?.gatsbyImageData}
								className={classes.headshot}
							/>
						</div>

						<Grid item container direction='row' justifyContent='center'>
							<a
								href={open[0].linkedIn ?? '#'}
								target='_blank'
								rel='noreferrer'>
								<FontAwesomeIcon
									icon={['fab', 'linkedin-in']}
									style={{
										color: 'white',
										height: '40px',
										width: '40px',
										border: `1px solid #002D5C`,
										borderRadius: '2px',
										background: '#002D5C',
										padding: '6px',
										textAlign: 'center',
										marginRight: '.5rem',
									}}
								/>
							</a>
							{open[0].email && (
								<a href={`mailto:${open[0].email}`}>
									<FontAwesomeIcon
										icon={['fas', 'envelope']}
										style={{
											color: 'white',
											height: '40px',
											width: '40px',
											border: `1px solid #002D5C`,
											borderRadius: '2px',
											background: '#002D5C',
											padding: '6px',
											textAlign: 'center',
											marginRight: '.5rem',
										}}
									/>
								</a>
							)}
						</Grid>
					</Grid>
					<Grid
						item
						lg={9}
						container
						direction='row'
						alignItems='space-between'
						className={classes.dialogWrapper}>
						<Grid
							container
							direction='row'
							justifyContent='flex-start'
							alignItems='space-evenly'>
							<PortableText
								content={open[0]._rawPosition}
								serializers={{
									normal: ({ children }) => {
										// Removing line break from leaders that have more than one title (i.e. Jill Davie)
										let team = [];
										if (children.length > 1) {
											team = [...children[0], ...children[2]];
										}
										return (
											<Typography
												variant='h6'
												style={{
													color: '#002D5C',
													fontWeight: 700,
													marginTop: lg && '1rem',
												}}>
												{open[0].title}, {team.length ? team : children}
											</Typography>
										);
									},
								}}
							/>
						</Grid>
						<Grid
							item
							container
							direction='column'
							justifyContent='flex-start'
							ref={textRef}
							style={{
								//minHeight: xs ? '30vh' : lg ? '20vh' : '420px',
								//maxHeight: xs ? '250px' : lg ? '25vh' : null,
								marginTop: lg && '1rem',
								overflowY: lg && 'scroll',
							}}>
							{open && (
								<PortableText
									content={
										xs
											? !openBody
												? open[0]._rawBody[0]
												: open[0]._rawBody
											: open[0]._rawBody
									}
									serializers={{
										normal: ({ children }) => {
											return (
												<>
													<Typography
														variant='body1'>
														{xs
															? !openBody
																? children[0]
																: children
															: children}
													</Typography>
													<br />
												</>
											);
										},
										bold: ({ children }) => (
											<Typography variant='body1' style={{ color: '#002D5C' }}>
												{children}
											</Typography>
										),
										emailPhoneLink: (props) => {
											return (
												<a
													href={props.emailPhoneHref}
													style={{ color: '#4B5B69' }}>
													{props.children[0]}
												</a>
											);
										},
									}}
								/>
							)}
						</Grid>
						<Grid item container direction='row' alignItems='center'>
							{xs && (
								<span
									onClick={() => setOpenBody(!openBody)}
									style={{ cursor: 'pointer' }}>
									<Typography color='primary' style={{ fontWeight: 700 }}>
										Read More {openBody ? '-' : '+'}
									</Typography>
								</span>
							)}

							<span
								style={{
									marginTop: 'auto',
									marginLeft: 'auto',
									color: '#002d5c',
									userSelect: 'none',
								}}>
								<FontAwesomeIcon
									icon={['fas', 'arrow-left']}
									style={{
										height: '32px',
										width: '32px',
										margin: '0 1rem',
										cursor: 'pointer',
									}}
									onClick={handlePrev}
								/>
								<FontAwesomeIcon
									icon={['fas', 'arrow-right']}
									style={{
										height: '32px',
										width: '32px',
										margin: '0 .5rem',
										cursor: 'pointer',
									}}
									onClick={handleNext}
								/>
							</span>
						</Grid>
					</Grid>
				</Grid>
			</Dialog>
		)
	);
};
