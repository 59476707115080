import React, { useEffect, useState, useContext } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { Container, Typography, Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Hero } from '../components/General/Hero';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { LeadershipCard } from '../components/General/Leadership/LeadershipCard';
import { LeadershipDialog } from '../components/General/Leadership/LeadershipDialog';
import { SEO } from '../components/SEO';
// import { Form } from '../components/ContactForm/Form';
import FormModalContext from '../context/FormModalContext';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	text: {
		color: '#5A7184',
		lineHeight: 1.8,
		'& a': {
			color: theme.workwaveBlue,
		},
	},
  leadershipCont: {
    marginBottom: '4rem', 
    marginTop: '-2rem',
    [theme.breakpoints.down('xl')]: {
      marginBottom: '11rem', 
    },
  },
}));

const LeadershipPage = ({ data, location }) => {
	const leadership = data.leadership.edges[0].node;
	const classes = useStyles();

	const {
		metaTitle,
		metaDescription,
		hero,
		executiveHeader,
		executiveCards,
		boardHeader,
		boardCards,
	} = leadership;

	const [selectedExecutive, setSelectedExecutive] = useState(null);
	const [selectedBoard, setSelectedBoard] = useState(null);
	const [cards, setCards] = useState([]);
	const [selected, setSelected] = useState(null);
	const { formModalOpen } = useContext(FormModalContext);

	useEffect(() => {
		setCards([...executiveCards, ...boardCards]);
	}, []);

	useEffect(() => {
		let selectedPerson;

		if (selectedExecutive !== null) {
			selectedPerson = cards.filter(
				(card) => card._key === executiveCards[selectedExecutive]._key
			);
		} else if (selectedBoard !== null) {
			selectedPerson = cards.filter(
				(card) => card._key === boardCards[selectedBoard]._key
			);
		} else {
			selectedPerson = null;
		}

		setSelected(selectedPerson);
	}, [selectedExecutive, selectedBoard]);

	const handleSelectExecutive = (e, index) => {
		setSelectedExecutive(index);
	};

	const handleSelectBoard = (e, index) => {
		setSelectedBoard(index);
	};

	const handleClose = () => {
		setSelectedExecutive(null);
		setSelectedBoard(null);
		setSelected(null);
	};

	const handleNext = (e, index) => {
		if (selectedExecutive !== null) {
			if (selectedExecutive === executiveCards.length - 1) {
				setSelectedExecutive(0);
			} else {
				setSelectedExecutive((selectedExecutive) => selectedExecutive + 1);
			}
		} else if (selectedBoard !== null) {
			if (selectedBoard === boardCards.length - 1) {
				setSelectedBoard(0);
			} else {
				setSelectedBoard((selectedBoard) => selectedBoard + 1);
			}
		} else {
			return;
		}
	};

	const handlePrev = (e, index) => {
		if (selectedExecutive !== null) {
			if (selectedExecutive === 0) {
				setSelectedExecutive(executiveCards.length - 1);
			} else {
				setSelectedExecutive((selectedExecutive) => selectedExecutive - 1);
			}
		} else if (selectedBoard !== null) {
			if (selectedBoard === 0) {
				setSelectedBoard(boardCards.length - 1);
			} else {
				setSelectedBoard((selectedBoard) => selectedBoard - 1);
			}
		} else {
			return;
		}
	};

	const xs = useMediaQuery('(max-width: 481px)');
	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />

			<Hero hero={hero} leadership={true} />
			<Container className={classes.leadershipCont}>
				<Grid
					container
					direction='row'
					justifyContent='center'
					alignItems='center'>
					<Grid item xs={12}>
						<Typography
							color='primary'
							variant='h2'
							style={{
								textAlign: 'center',
								padding: '2rem 0',
							}}>
							{executiveHeader}
						</Typography>
					</Grid>
					<Grid
						container
						direction='row'
						justifyContent={xs ? 'center' : 'flex-start'}
						spacing={2}>
						{executiveCards.map((card, index) => (
							<LeadershipCard
								key={index}
								card={card}
								index={index}
								handleSelect={handleSelectExecutive}
							/>
						))}
					</Grid>
				</Grid>
				<Grid
					style={{ padding: '4rem 0 8rem 0' }}
					container
					direction='row'
					justifyContent='center'
					alignItems='center'>
					<Grid item xs={12}>
						<Typography
							color='primary'
							variant='h2'
							style={{
								textAlign: 'center',
								padding: '2rem 0',
							}}>
							{boardHeader}
						</Typography>
					</Grid>
					<Grid
						container
						direction='row'
						justifyContent={xs ? 'center' : 'flex-start'}
						spacing={2}>
						{boardCards.map((card, index) => (
							<LeadershipCard
								key={index}
								card={card}
								index={index}
								handleSelect={handleSelectBoard}
							/>
						))}
					</Grid>
				</Grid>
				<LeadershipDialog
					handleClose={handleClose}
					open={selected}
					handleNext={handleNext}
					handlePrev={handlePrev}
				/>
				{formModalOpen ? (
					<Form
						privacy
						noForm
						location={location}
						pardotUrl='https://go.workwave.com/l/1042281/2023-11-16/65swnn'
					/>
				) : null}
			</Container>
			<div>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
		</>
	);
};

export const query = graphql`
	query LeadershipPageQuery {
		leadership: allSanityLeadership {
			edges {
				node {
					metaTitle
					metaDescription
					hero {
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						_rawContent
					}
					executiveHeader
					executiveCards {
						_key
						title
						_rawPosition
						headshot {
							asset {
								gatsbyImageData(placeholder: BLURRED, height: 300, width: 250)
							}
						}
						cardAccentColor {
							hexValue
						}
						linkedIn
						email
						_rawBody
					}
					boardHeader
					boardCards {
						_key
						title
						_rawPosition
						headshot {
							asset {
								gatsbyImageData(placeholder: BLURRED, height: 300, width: 250)
							}
						}
						cardAccentColor {
							hexValue
						}
						linkedIn
						email
						_rawBody
					}
				}
			}
		}
	}
`;

export default LeadershipPage;
