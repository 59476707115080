import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from 'react-portable-text';

import { Typography, Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		position: 'relative',
		cursor: 'pointer',
		'&:hover': {
			'& $headshotBackground': {
				opacity: 0.7,
			},
		},
	},
	headshotBackground: {
		position: 'absolute',
		padding: 'inherit',
		width: '100%',
		zIndex: 0,
		aspectRatio: '1/1',
		borderRadius: '50%',
		opacity: 0.1,
		// height: '250px',
		transition: 'all .25s ease-in-out',
		bottom: 0,
	},
	headshot: {
		position: 'relative',
		zIndex: 1,
		opacity: 1,
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
}));

export const LeadershipCard = ({ card, index, handleSelect }) => {
	const classes = useStyles();
	const xs = useMediaQuery('(max-width: 481px)');
	return (
		<Grid
			item
			lg={3}
			md={4}
			sm={6}
			xs={12}
			onClick={(e) => handleSelect(e, index)}
			container
			direction='column'
			justifyContent={xs ? 'center' : 'flex-start'}
			alignItems='center'>
			<div className={classes.wrapper}>
				<div
					className={classes.headshotBackground}
					style={{
						background: card.cardAccentColor[0].hexValue ?? '#002d5c',
					}}
				/>
				<GatsbyImage
					image={card.headshot.asset?.gatsbyImageData}
					className={classes.headshot}
				/>
			</div>

			<Grid
				item
				container
				direction='column'
				justifyContent='center'
				alignItems='center'
				style={{ marginTop: '1rem', lineHeight: '1.3rem' }}>
				<Typography color='primary' variant='body1' style={{ fontWeight: 700 }}>
					{card.title}
				</Typography>
				{card._rawPosition.map((content, index) => (
					<PortableText
						key={index}
						content={content}
						// className={classes.text}
						serializers={{
							normal: ({ children }) => (
								<Typography
									variant='body1'
									style={{
										fontSize: '1rem',
										fontWeight: 400,
										textAlign: 'center',
										fontFamily: 'Roboto',
									}}>
									{children}
								</Typography>
							),
						}}
					/>
				))}
			</Grid>
		</Grid>
	);
};
